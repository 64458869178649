//import axios from 'axios';

export function enviarEmailUsuarioCadastro(credential) {
	const assunto =
		'Plataforma de Entrega de Dados do Cemaden (PED) - Envio de Senha Internet'
	const texto = `
    <div>
        <p>Prezado usuário(a),</p>
        <p>&nbsp;</p>
        <table width="100%">
        <tbody>
        <tr>
        <th></th>
        <td>
        O seu cadastro na Plataforma de Entrega de Dados (PED)
        foi criado com sucesso:
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>
        </tr>
        <tr>           				
        <th></th>
        <td>
        <b>Usuário:</b> <strong>${credential.email}</strong>
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>

        </tr>
        <tr>           				
        <th></th>
        <td>
        <b>Senha de acesso: </b><strong>${credential.password}</strong>
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>
        </tr>
        <tr>
        <th></th>
        <td>
        Clique aqui para acessar a plataforma: <a href="https://ped.cemaden.gov.br">Plataforma PED</a>.
        </td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p>Atenciosamente,</p>
        <p>&nbsp;</p>
        <p>Equipe do PED</p>
        <p>Divisão de Produtos Integrado - DIPIN</p>
        <p>Centro Nacional de Monitoramento e Alertas de Desastres Naturais - Cemaden</p>
        <p>&nbsp;</p>
        <p>::: MENSAGEM GERADA AUTOMATICAMENTE :::</p>
        <p>&nbsp;</p>
    </div>
        `

	const data = {
		para: credential.email,
		assunto: assunto,
		texto: texto,
	}

	// const porta = 3000
	const urlServidor = `https://ped.cemaden.gov.br/enviar-email`

	// Fazer uma solicitação HTTP POST para o servidor Node.js
	fetch(urlServidor, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then((response) => response.text())
		.then((message) => alert(message))
		.catch((error) => console.error('Erro ao enviar o email:', error))
}

export function enviarEmailUsuarioAlteraSenha(credential) {
	const assunto =
		'Plataforma de Entrega de Dados do Cemaden (PED) - Envio de Senha Internet'
	const texto = `
    <div>
        <p>Prezado usuário(a),</p>
        <p>&nbsp;</p>
        <table width="100%">
        <tbody>
        <tr>
        <th></th>
        <td>
        A sua senha de acesso à Plataforma de Entrega de Dados (PED)
        foi alterada com sucesso:
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>
        </tr>
        <tr>	
        <th></th>
        <td>
        <strong>${credential.password}</strong>
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>
        </tr>
        <tr>      				
        <th></th>				
        <td>
        através da opção <strong><em>Alterar Senha</em></strong>.
        </td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p>Atenciosamente,</p>
        <p>&nbsp;</p>
        <p>Equipe do PED</p>
        <p>Divisão de Produtos Integrado - DIPIN</p>
        <p>Centro Nacional de Monitoramento e Alertas de Desastres Naturais - Cemaden</p>
        <p>&nbsp;</p>
        <p>::: MENSAGEM GERADA AUTOMATICAMENTE :::</p>
        <p>&nbsp;</p>
    </div>
        `

	const data = {
		para: credential.email,
		assunto: assunto,
		texto: texto,
	}

	// const porta = 3000
	const urlServidor = `https://ped.cemaden.gov.br/enviar-email`

	// Fazer uma solicitação HTTP POST para o servidor Node.js
	fetch(urlServidor, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then((response) => response.text())
		.then((message) => alert(message))
		.catch((error) => console.error('Erro ao enviar o email:', error))
}

export async function enviarEmailUsuarioEsqueciSenha(credential) {
	const assunto =
		'Plataforma de Entrega de Dados do Cemaden (PED) - Envio de Senha Internet'
	const texto = `
    <div>
        <p>Prezado usuário(a),</p>
        <p>&nbsp;</p>
        <table width="100%">
        <tbody>
        <tr>
        <th></th>
        <td>
        A sua nova senha de acesso à Plataforma de Entrega de Dados (PED)
        foi gerada com sucesso:
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>
        </tr> 
        <tr>           				
        <th></th>
        <td>
        <strong>${credential.password}</strong>
        </td>
        </tr>
        <tr>
        <th></th>
        <td colspan="3">&nbsp;
        </td>
        </tr>
        <tr>
        <th></th>
        <td>
        Após o primeiro acesso, utilize a opção <strong><em>Alterar Senha</em></strong> para definir 
        uma senha pessoal.
        </td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p>Atenciosamente,</p>
        <p>&nbsp;</p>
        <p>Equipe do PED</p>
        <p>Divisão de Produtos Integrado - DIPIN</p>
        <p>Centro Nacional de Monitoramento e Alertas de Desastres Naturais - Cemaden</p>
        <p>&nbsp;</p>
        <p>::: MENSAGEM GERADA AUTOMATICAMENTE :::</p>
        <p>&nbsp;</p>
    </div>
        `

	const data = {
		para: credential.email,
		assunto: assunto,
		texto: texto,
	}
 
	
	// const porta = 3000
	const urlServidor = `https://ped.cemaden.gov.br/enviar-email`
	
	console.log('Dados Enviados:',data);
	
	
	//const axios = require('axios')

	/*

	// Fazer uma solicitação HTTP POST para o servidor Node.js com Axios
	await axios.post(urlServidor, data)
		.then(response => {
			alert(response.data);
		})
		.catch(error => {
			console.error('Erro ao enviar o email:', error);
		});

		
	*/

	// Fazer uma solicitação HTTP POST para o servidor Node.js
	fetch(urlServidor, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then(response => {
			console.log('Resposta recebida:', response);
			return response.json();
		})
		//.then(response => response.json())
		.then(data => {
			console.log('Dados da resposta:', data);
		})
		//.then((response) => response.text())
		.then((message) => alert(message))
		.catch((error) => console.error('Erro ao enviar o email:', error))
	
	
}
