<template>
	<div class="menu-item" @click="isOpen = !isOpen" @mouseleave="isOpen = false">
		<!--
    <a href ='#'>       
          {{ title }}
    </a>
    -->
		<label
			>Suporte
			<i class="fas fa-info-circle custom-arrow-icon" aria-hidden="true"></i
		></label>
		<transition name="fade" apear>
			<div class="sub-menu" v-if="isOpen">
				<div class="menu-item" id="ajuda">
					<div>
						<a
							href="/manual_Plataforma_web_PED_v1.1.pdf"
							target="_blank"
							style="max-width: 100%"
							>Manual de Utilização</a
						>
					</div>
				</div>

				<div class="menu-item" id="faq">
					<router-link to="/suporte/faq" class="nav-item nav-link"
						>FAQ</router-link
					>
				</div>
				
				<div class="menu-item" id="termouso">
					<router-link to="/suporte/termouso" class="nav-item nav-link">Termos de Uso</router-link>
				</div>

			</div>
		</transition>
	</div>
</template>

<script>
	// Importe os estilos CSS do Font Awesome
	import '@fortawesome/fontawesome-free/css/all.css'

	export default {
		name: 'services',
		props: ['title'],
		data() {
			return {
				isOpen: false,
			}
		},
	}
</script>

<style>
	nav .menu-item svg {
		width: 10px;
		margin-left: 10px;
	}
	nav .menu-item .sub-menu {
		position: absolute;
		background-color: #3276b1;
		top: calc(100% + 5px);
		left: 50%;
		transform: translateX(-50%);
		width: max-content;
		border-radius: 0px 0px 16px 16px;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.2s ease-out;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
	.sub-menu {
		cursor: pointer;
	}
</style>
