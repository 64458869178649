<template>
  <div id="divObs" class="container">
    <div class="home-route" align="center">
      <h1>{{termos_uso_msg}}</h1>
      <div class="gap-60"></div>
      <div class="row">
        <label class="col-lg-8">
          <h2>Condições de Acesso</h2>
        </label>

        <div class="col-lg-9" align="center">

          <ol>

            <li class="list-group-item">Os dados e as informações fornecidas pela Plataforma de Entrega de Dados (PED) são disponibilizados 
              pelo CEMADEN como serviço público gratuito a qualquer pessoa física ou jurídica.
            </li>          

          </ol>

        </div>
      </div>

      <div class="row">
        <label class="col-lg-8">
          <h2>Atribuição de Autoria</h2>
        </label>

        <div class="col-lg-9" align="center">

          <ol>

            <li class="list-group-item">Com o fim de garantir que os princípios da Lei de Acesso
               a Informação (Lei 12.527 de 18 de novembro de 2011) sejam resguardados, recomenda-se
                que a fonte de dados, produzidos pelo CEMADEN, decorrentes do desenvolvimento científicos ou tecnológicos,
                possa ser atribuída oportunamente em eventos, publicações e ações, dentre outros.
                Ao utilizar dados, conteúdo ou documentos disponíveis na API ou na Plataforma de Entrega de Dados do CEMADEN, 
                o usuário deverá, preferencialmente, citá-lo como fonte. A citação deverá ser feita indicando o nome 
                Centro Nacional de Monitoramento e Alertas de Desastres Naturais - CEMADEN/MCTI e o endereço URL do Portal 
                disponível em: <a href="https://www.gov.br/cemaden/pt-br" target="_blank">www.gov.br/cemaden/</a>.
              Caso sejam disponibilizados no Portal dados de outras fontes que não o Cemaden, o usuário deverá citá-las como fonte dos dados, 
              além de observar a licença ou termos de uso adicionais aplicáveis ao conjunto de dados.
            </li>          

          </ol>

        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
export default {
  name: 'termouso',
  data() {
    return {
      termos_uso_msg: 'TERMOS DE USO'
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*Evita a geração de scroll horizontal*/
.row {
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-margin-top {
  margin-top: 20px;
}


.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  vertical-align: middle;
}
.gap-60 {
	width: 100%;
	height: 60px;
}

h1,
h2 {
  font-weight: bold;
}

p {
  color: #000;
  font-size: 24px;
  width: 85%;
  padding: 30px;
}

ul {
  padding: 0;
}

li {
  text-align: justify;
  font-size: 18px;
  margin: auto;
  width: 100%;
  padding: 20px;
}

a {
  color: #42b983;
}

.home-route {
  padding: 20px;
}

p {
  padding: 10px;
}
</style>
